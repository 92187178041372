h1 {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 120%;
  color: $white;

  @media (max-width: $tablet) {
    font-size: 55px;
  }

  @media (max-width: $mobile) {
    font-size: 34px;
  }
}

h2 {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 135%;
  font-feature-settings: 'liga' off;
  color: $white;

  @media (max-width: $tablet) {
    font-size: 42px;
  }

  @media (max-width: $mobile) {
    font-size: 30px;
  }
}

h3 {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 140%;
  color: $white;

  @media (max-width: $tablet) {
    font-size: 34px;
  }

  @media (max-width: $tablet) {
    font-size: 28px;
  }
}

h4 {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 120%;
  color: $white;
}

.regularText {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: 'liga' off;
  color: $white;
}

.regularText2 {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 155%;
  color: $white;
}

.regularText {
  font-family: $fontArnhem;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 155%;
  font-feature-settings: 'liga' off;
  color: $white;

  @media (max-width: $mobile) {
    font-size: 16px;
    line-height: 155%;
  }
}

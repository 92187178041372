.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $darker;
  padding: 50px 50px 30px;

  .footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .top-section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;

      @media (max-width: $tablet) {
        flex-direction: column;
        margin-bottom: 80px;
      }

      @media (max-width: $mobile) {
        margin-bottom: 40px;
      }

      .right-section {
        width: calc(50% - 40px);
        @extend .regularText2;
        font-family: $fontHaas;
        color: $white;
        margin: 0;

        @media (max-width: $tablet) {
          width: 100%;
          margin-bottom: 40px;
        }
      }

      .newsletter-section {
        width: calc(50% - 40px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: $tablet) {
          width: 100%;
        }

        .newsletter-info {
          @extend .regularText2;
          font-family: $fontHaas;
          color: $white;
          margin: 0 0 20px;
        }

        .newsletter-heading {
          font-family: $fontHaas;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 140%;
          color: $white;
          margin: 0 0 20px;
        }

        $red: #f85757;
        $green: #62f862;

        .alert {
          display: none;
          margin: 0 0 20px;
          padding: 5px 15px;
          border-radius: 5px;
          font-family: $fontHaas;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: $colorAccent1;
          border: 1px solid $colorAccent1;
          background-color: rgba($colorAccent1, 0.25);

          &.is-active {
            display: flex;
          }

          &.error {
            color: $red;
            border: 1px solid $red;
            background-color: rgba($red, 0.25);
          }
        }

        .newsletter-form {
          width: 100%;
          max-width: 455px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: $mobile) {
            flex-direction: column;
          }

          .newsletter-input {
            flex: 1;
            font-family: $fontHaas;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 155%;
            color: #888888;
            padding: 12px 20px;
            background: $white;
            border-radius: 10px;
            border: none;
            appearance: none;
            outline: none;

            @media (max-width: $mobile) {
              width: 100%;
            }
          }

          .newslettter-submit {
            font-family: $fontHaas;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 155%;
            text-align: center;
            color: $white;
            border: none;
            appearance: none;
            outline: none;
            padding: 12px 34px;
            background: $colorAccent1;
            border-radius: 10px;
            margin-left: 16px;
            flex-shrink: 0;
            cursor: pointer;

            @media (max-width: $mobile) {
              width: 100%;
              margin: 16px 0 0;
            }
          }
        }
      }
    }

    .bottom-section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: $mobile) {
        align-items: flex-start;
        flex-direction: column;
      }

      .footer-rights {
        font-family: $fontHaas;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 138.5%;
        text-transform: capitalize;
        color: $white;
        margin: 0 50px 0 0;

        @media (max-width: $mobile) {
          margin: 0 0 16px;
        }
      }

      .footer-link {
        font-family: $fontHaas;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 138.5%;
        color: $white;
        margin: 0;
      }
    }
  }
}

.home-services001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 200px 0;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding: 80px 0;
  }

  .home-services001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .services001-list {
      width: 100%;
      display: flex;
      flex-direction: column;

      .service001-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 150px;

        &:nth-child(even) {
          flex-direction: row-reverse;

          @media (max-width: $tablet) {
            flex-direction: column;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: $tablet) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 80px;
        }

        .item-image-container {
          width: 34%;
          display: flex;

          @media (max-width: $tablet) {
            width: 100%;
            max-width: 200px;
            margin-bottom: 40px;
          }

          @media (max-width: $mobile) {
            max-width: 140px;
          }

          .item-image {
            width: 100%;
          }
        }

        .item-content-container {
          width: 60%;
          display: flex;
          flex-direction: column;

          @media (max-width: $tablet) {
            width: 100%;
          }

          .item-heading {
            color: $darker;
            margin: 0 0 10px;
          }

          .item-description {
            @extend .regularText2;
            color: $darker;
            margin: 0;
          }
        }
      }
    }
  }
}

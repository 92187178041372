.terms-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(112.73deg, #104E65 0.12%, #012030 100%);
  min-height: 100vh;

  .terms-content {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 250px;
    margin-bottom: 250px;
    background: $white;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    padding: 80px 100px;

    @media (max-width: $mobile) {
      min-height: auto;
      margin-top: 150px;
    }

    p {
      font-family: $fontArnhem;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 156%;
      color: $dark;
      margin: 0 0 40px;
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0 0 40px;
      color: $dark;
    }

    h2 {
      @extend h4;
      color: $dark;
    }

    ul {
      color: #73879A;
      //  margin: 0 0 40px;
      //  padding: 0;
      //  list-style: none;
      //
      //  li {
      //    position: relative;
      //    padding-left: 30px;
      //
      //    &:before {
      //      position: absolute;
      //      content: '';
      //      left: 0;
      //      top: 8px;
      //      width: 11px;
      //      height: 11px;
      //      border-radius: 11px;
      //      border: 2px solid $colorAccent1;
      //    }
      //  }

      li {
        font-family: $fontArnhem;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 156%;
        color: $dark;
      }
    }

    img {
      margin: 0 auto 40px;
      max-width: 100%;
      height: 100%;
    }

    a {
      text-decoration: underline;
      color: $colorAccent1;
    }
  }
}

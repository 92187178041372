.form-success {
  position: fixed;
  inset: 0;
  z-index: 100;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-out .2s;

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  .form-success-layout {
    position: absolute;
    inset: 0;
    background-color: rgba($black, .5);
  }

  .form-success-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 768px;
    padding: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    box-shadow: 0 5px 30px rgba($black, .6);
    border-radius: 10px;

    p {
      @extend h3;
      color: $dark;
      text-align: center;
      margin: 0 0 40px;
    }

    .success-button {
      @extend .button;
      @extend .text-dark;
    }

    .success-close {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 40px;
      height: 40px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBhdGggZD0iTTEgMTkgMTkgMU0xLjAwMSAxbDE4IDE4IiBzdHJva2U9IiM2OTY5NjkiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==");
      background-position: center center;
      background-size: 18px 18px;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}

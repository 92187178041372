.home-team001 {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url('/img/site/homeTeam-background.png') no-repeat center center / cover;
  padding: 100px 0;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding: 60px 0;
  }

  .home-team001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .team001-heading {
      width: 100%;
      font-family: $fontArnhem;
      color: $white;
      margin: 0 0 30px;
    }

    .team001-text {
      width: 100%;
      font-family: $fontArnhem;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 156%;
      color: $white;
      margin: 0 0 30px;
    }

    .team001-list {
      width: 100%;
      padding: 50px 0 60px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: $mobile) {
        padding: 40px 0;
      }

      .swiper-wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      .team001-item {
        width: calc((100% - 400px) / 3);
        max-width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $tablet) {
          width: calc((100% - 40px) / 3);
        }

        @media (max-width: $mobile) {
          width: 80%;
          max-width: 250px;
          margin: 0 10px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .item-image-container {
          width: 100%;
          margin-bottom: 20px;

          .item-image {
            width: 100%;
          }
        }

        .item-heading {
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 145%;
          font-feature-settings: 'liga' off;
          color: $white;
          margin: 0;
          text-align: center;

          @media (max-width: $mobile) {
            font-size: 18px;
          }
        }

        .item-role {
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 180%;
          font-feature-settings: 'liga' off;
          color: $white;
          margin: 0 0 5px;
          text-align: center;

          @media (max-width: $mobile) {
            font-size: 16px;
          }
        }

        .item-description {
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 150%;
          font-feature-settings: 'liga' off;
          color: $white;
          margin: 0;
          text-align: center;

          @media (max-width: $mobile) {
            font-size: 14px;
          }
        }
      }
    }

    .team001-button {
      position: relative;
      @extend .button;
    }
  }
}

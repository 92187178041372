.hero-socials {
  position: absolute;
  left: 50px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $mobile) {
    display: none;
  }

  .social-link {
    display: flex;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    svg {
      height: 16px;
    }
  }
}

.hero-arrow {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);

  @media (max-width: $mobile) {
    display: none;
  }

  svg {
    height: 32px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $fontHaas;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  background-color: transparent;
  color: $white;
  padding: 16px 50px;
  border: 1px solid $colorAccent1;
  border-radius: 110px;
  transition: all ease-out .2s;
  appearance: none;
  outline: none;
  cursor: pointer;
  text-align: center;

  @media (max-width: $mobile) {
    padding: 16px;
  }

  @media (max-width: 520px) {
    width: 100%;
  }

  &.text-dark {
    color: $dark;

    &:hover {
      color: $white;
    }
  }

  &.colored {
    background-color: $colorAccent1;

    &:hover {
      color: $colorAccent1;
      background-color: $white;
      border-color: $white;
    }
  }

  &:hover {
    background-color: $colorAccent1;
  }

  &:active, &:focus {
    border-color: $colorAccent1;
  }

  &:disabled, &.disabled {
    color: $silver;
    border-color: $silver;
    pointer-events: none;
  }
}

.arrow-link {
  display: flex;
  align-items: center;
  font-family: $fontHaas;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  color: $white;
  transition: color ease-out .2s;

  &.text-dark {
    color: $dark;
  }

  &:hover {
    color: $colorAccent1;
  }

  &:after {
    margin-left: 10px;
    display: flex;
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxMCI+PHBhdGggZD0iTTkuOTI2IDEuMTg1YS42OTQuNjk0IDAgMSAxIC45ODItLjk4Mmw0LjE2NyA0LjE2N2EuNjk1LjY5NSAwIDAgMSAwIC45ODJMMTAuOTA4IDkuNTJhLjY5NC42OTQgMCAwIDEtLjk4Mi0uOTgybDIuOTgxLTIuOTgxSC43MDJBLjY5OC42OTggMCAwIDEgMCA0Ljg2YzAtLjM4My4zMTQtLjY5NC43MDItLjY5NGgxMi4yMDVMOS45MjYgMS4xODVaIiBmaWxsPSIjMDBBNEZGIi8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

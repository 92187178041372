.wallet-projects001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 160px 0;
  overflow: hidden;
  margin-top: 100px;

  @media (max-width: $tablet) {
    padding: 60px 0;
    margin-top: 60px;
  }

  .wallet-projects001-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .projects001-heading {
      color: $darker;
      margin: 0 0 30px;
    }

    .projects001-text {
      @extend .regularText;
      color: $darker;
      margin: 0 0 80px;

      @media (max-width: $mobile) {
        margin-bottom: 40px;
      }
    }

    .projects001-buttons {
      display: flex;
      align-items: center;
      margin-bottom: 60px;

      @media (max-width: $mobile) {
        align-items: flex-start;
        flex-direction: column;
      }

      .projects001-button {
        font-family: $fontHaas;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        padding: 16px 40px;
        color: $darker;
        background: #CCD5DB;
        border-radius: 110px;
        margin-right: 16px;
        transition: all ease-out .2s;

        &:hover {
          background: $darker;
          color: $white;
        }

        @media (max-width: $mobile) {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }

    .projects001-subtitle {
      color: $darker;
      margin: 0 0 55px;
    }

    .projects001-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      .project-item {
        width: calc((100% - 40px) / 3);
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        @media (max-width: $tablet) {
          width: calc((100% - 20px) / 2);

          &:nth-child(3n+3) {
            margin-right: 20px;
          }

          &:nth-child(2n+2) {
            margin-right: 0;
          }
        }

        @media (max-width: $mobile) {
          width: 100%;
          margin-right: 0;

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }

        .item-logo-container {
          width: 100%;
          display: flex;

          .item-logo {
            width: 100%;
          }
        }

        .item-footer {
          flex: 1;
          min-height: 140px;
          width: 100%;
          display: flex;
          padding: 30px 25px;
          background-color: $colorAccent3;

          @media (max-width: $tablet) {
            padding: 20px 15px;
          }

          .item-description {
            font-family: $fontHaas;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 130%;
            color: $white;
            margin: 0;
          }
        }
      }
    }
  }
}

// --------------------------------------------------
// Variables
// --------------------------------------------------
@import url("https://use.typekit.net/bnh8txt.css");

// Fonts
$fontArnhem: 'Arnhem', 'Arial', 'sans-serif';
$fontHaas: 'neue-haas-grotesk-display', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #00A4FF;
$colorAccent2: #1058E8;
$colorAccent3: #355565;

// Gradients
$gradient1: linear-gradient(112.73deg, #104E65 0.12%, #012030 100%);
$gradient2: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);

// Texts
$black: #000000;
$darker: #062D3F;
$dark: #333333;
$text: #474747;
$silver: #C6C6C6;
$white: #FFFFFF;

// Inputs
$battleshipGrey: #888888;
$lightBlue: #F2F7FF;

$colorSuccess: #00966D;
$colorError: #FF564A;

// Breakpoints
$desktop: 1200px;
$tablet: 1023px;
$mobile: 767px;

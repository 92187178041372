.home-vision001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0 50px;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding: 60px 0 50px;
  }

  .home-vision001-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .vision001-heading {
      font-family: $fontArnhem;
      color: $darker;
      margin: 0 0 30px;
    }

    .vision001-text {
      width: 100%;
      font-family: $fontArnhem;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 156%;
      color: $darker;
      margin: 0 0 20px;

      @media (max-width: $tablet) {
        margin-bottom: 60px;
      }
    }

    .vision001-citation-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $mobile) {
        flex-direction: column-reverse;
      }

      .citation-image-container {
        position: relative;
        width: 50%;
        transform: translate(-80px, 50px);

        @media (max-width: $tablet) {
          width: 35%;
          transform: translate(-40px, 50px);
        }

        @media (max-width: $mobile) {
          width: 100%;
          transform: translate(0, 50px);
        }

        &:before {
          content: '';
          position: absolute;
          width: 474px;
          height: 140px;
          left: 50%;
          top: -20px;
          background: linear-gradient(90deg, #062D3F 0%, rgba(255, 255, 255, 0) 100%);
          opacity: 0.3;
          z-index: 2;

          @media (max-width: $tablet) {
            width: 200px;
            height: 75px;
          }
        }

        &:after {
          content: '';
          position: absolute;
          width: 400px;
          height: 210px;
          bottom: 0;
          right: 175px;
          background: linear-gradient(90deg, #062D3F 0%, rgba(255, 255, 255, 0) 100%);
          opacity: 0.3;
          z-index: 2;

          @media (max-width: $tablet) {
            width: 200px;
            height: 100px;
            right: 50px;
          }

          @media (max-width: $mobile) {
            width: 250px;
            height: 100px;
            right: 50%;
          }
        }

        .citation-image-wrapper {
          position: relative;
          width: 100%;
          padding-top: 100%;
          z-index: 3;

          .citation-image {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: bottom right;
          }
        }
      }

      .citation-container {
        position: relative;
        z-index: 3;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @media (max-width: $tablet) {
          width: 65%;
        }

        @media (max-width: $mobile) {
          width: 100%;
        }

        .vision001-citation {
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: bold;
          font-size: 21px;
          line-height: 160%;
          color: $darker;
          margin: 0 0 10px;
        }

        .vision001-author {
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 160%;
          text-align: right;
          color: $darker;
          margin: 0;
        }
      }
    }
  }
}

.home-ecosystem001 {
  width: 100%;
  display: flex;
  justify-content: center;
  background: url('/img/site/buildHero-background.png') no-repeat center center / cover;
  padding: 150px 0;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: $mobile) {
    padding: 100px 0;
  }

  .home-ecosystem001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobile) {
      min-height: auto;
    }

    .ecosystem001-heading {
      width: 100%;
      text-align: left;
      margin: 0 0 30px;

      @media (max-width: $tablet) {
        margin-bottom: 16px;
      }
    }

    .ecosystem001-text {
      width: 100%;
      font-family: $fontArnhem;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 155%;
      color: $white;
      text-align: left;
      margin: 0 0 60px;

      @media (max-width: $tablet) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    .ecosystem001-list {
      width: 100%;
      padding: 50px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: $mobile) {
        flex-direction: column;
        align-items: center;
        max-width: 400px;
        padding-top: 20px;
      }

      .ecosystem001-item {
        width: calc((100%) / 3);
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $mobile) {
          width: 100%;
        }

        .ecosystem001-item-wrapper {
          position: relative;
          width: 100%;
          padding-top: 100%;

          &:before {
            content: '';
            position: absolute;
            inset: 14.5%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);;
            border: 1px solid $white;
            box-sizing: border-box;
            transform: rotate(45deg);
          }

          .ecosystem001-item-container {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 63%;
            transform: translate(-50%, -40%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .item-heading {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: $fontArnhem;
              font-style: normal;
              font-weight: bold;
              font-size: 28px;
              line-height: 135%;
              text-align: center;
              color: $white;
              margin: 0 0 18px;
              min-height: 76px;

              @media (max-width: $tablet) {
                font-size: 18px;
                margin-bottom: 10px;
              }
            }

            .item-link {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 145%;
              color: $white;
              padding-bottom: 30px;
              background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMyAyMSI+PHBhdGggZD0iTTExLjQxNSAxMy40NjFhLjkyLjkyIDAgMCAxIDEuMzEzIDAgLjk1MS45NTEgMCAwIDEgMCAxLjMzMmwtNS41NzEgNS42NWEuOTIuOTIgMCAwIDEtMS4zMTQgMGwtNS41NzEtNS42NWEuOTUxLjk1MSAwIDAgMSAwLTEuMzMyLjkyLjkyIDAgMCAxIDEuMzEzIDBsMy45ODYgNC4wNDNWLjk1MkEuOTQuOTQgMCAwIDEgNi41IDBhLjk0Ljk0IDAgMCAxIC45MjkuOTUydjE2LjU1MmwzLjk4Ni00LjA0M1oiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
              background-repeat: no-repeat;
              background-position: bottom center;
              background-size: auto 16px;
            }
          }
        }
      }
    }
  }
}

.form-row {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.form-group {
  width: 100%;

  &.is-half {
    width: calc(50% - 7.5px);

    &:first-of-type {
      margin-right: 15px;
    }

    @media (max-width: $mobile) {
      width: 100%;

      &:first-of-type {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }
}

.form-input {
  width: 100%;
  padding: 17px 20px 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  font-family: $fontHaas;
  color: $text;
  line-height: 21px;
  border: none;
  border-radius: 8px;
  background: $lightBlue;
  resize: none;

  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: #888888;
  }

  &:focus {
    outline: 0;
  }

  &.is-white {
    background: $white;

    &::placeholder,
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $battleshipGrey;
    }
  }

  @media (max-width: $mobile) {
    font-size: 16px;
  }
}

textarea {
  height: 120px;

  &::placeholder {
    color: #888;
  }
}

input::placeholder {
  color: #888;
}

.form-button {
  position: relative;
  margin: 30px auto 0;
  @extend .button;

  &.text-dark {
    @extend .text-dark;
  }
}

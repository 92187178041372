.home-hero {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background: url('/img/site/homeHero-background.png') no-repeat center center / cover;

  .home-hero-container {
    @extend .container;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: $mobile) {
      min-height: auto;
      padding-top: 150px;
      padding-bottom: 60px;
    }

    .home-hero-heading {
      color: $white;
      margin: 0 0 30px;
    }

    .home-hero-text {
      font-family: $fontArnhem;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 145%;
      font-feature-settings: 'liga' off;
      color: $white;
      margin: 0 0 40px;

      @media (max-width: $tablet) {
        font-size: 24px;
      }

      @media (max-width: $mobile) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .home-hero-buttons {
      width: 100%;
      display: flex;
      align-items: center;

      @media (max-width: $mobile) {
        flex-direction: column;
      }

      .home-hero-button {
        margin: 20px 20px 0 0;
        @extend .button;

        @media (max-width: $mobile) {
          margin: 20px 0 0;
        }

        &:first-child {
          @extend .colored;
        }
      }
    }
  }
}

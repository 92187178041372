.classic-form {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $white;
  padding: 100px 0;

  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  .classic-form-container {
    @extend .container;
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet) {
      flex-direction: column;
    }

    .side-section {
      width: calc(50% - 40px);
      display: flex;
      flex-direction: column;

      @media (max-width: $tablet) {
        width: 100%;

        &:first-child {
          margin-bottom: 40px;
        }
      }
    }

    .classic-form-heading {
      font-family: $fontArnhem;
      color: $darker;
      margin: 0;
    }

    .classic-form-text {
      font-family: $fontArnhem;
      color: $darker;
      margin: 40px 0 0;
    }

    .form {
      width: 100%;

      .form-button {
        width: 100%;
      }
    }
  }
}

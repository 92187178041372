.strategy-value {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 250px 0 200px;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding: 60px 0 50px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 57vw;
    top: 0;
    width: 40vw;
    height: 30vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzUgNDU2Ij48cGF0aCBvcGFjaXR5PSIuMyIgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCA1NzUgNDU2KSIgZmlsbD0idXJsKCNhKSIgZD0iTTU3NSA0NTZoNTc1djQ1Nkg1NzV6Ii8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iMTE2Ni45MSIgeTE9IjQ0Mi4xNyIgeDI9Ijk2MC4wNTUiIHkyPSIxMDYxLjI0IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmZiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzA2MkQzRiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==");

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 56vw;
    bottom: -6vw;
    width: 49vw;
    height: 40vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MTQgNTgwIj48cGF0aCBvcGFjaXR5PSIuMyIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNDIzLjU3MSAwKSIgZmlsbD0idXJsKCNhKSIgZD0iTTQyMy41NzEgMGg0MTB2NDEwaC00MTB6Ii8+PHBhdGggb3BhY2l0eT0iLjMiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDI4OS45MTQgMCkiIGZpbGw9InVybCgjYikiIGQ9Ik0yODkuOTE0IDBoNDEwdjQxMGgtNDEweiIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9Ijg0NS42MjYiIHkxPSItMTIuNDM1IiB4Mj0iNjI0LjIyNSIgeTI9IjUxMy4wNDIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMDYyRDNGIi8+PC9saW5lYXJHcmFkaWVudD48bGluZWFyR3JhZGllbnQgaWQ9ImIiIHgxPSI3MTEuOTY5IiB5MT0iLTEyLjQzNSIgeDI9IjQ5MC41NjgiIHkyPSI1MTMuMDQyIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agc3RvcC1jb2xvcj0iI2ZmZiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzA2MkQzRiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==");

    @media (max-width: $mobile) {
      display: none;
    }
  }

  .strategy-value-container {
    position: relative;
    z-index: 2;
    @extend .container;
    display: flex;
    flex-direction: column;

    .strategy-hero-heading {
      font-family: $fontArnhem;
      color: $darker;
      margin: 0 0 55px;

      @media (max-width: $mobile) {
        margin-bottom: 20px;
      }
    }

    .strategy-hero-text {
      @extend .regularText;
      width: 100%;
      max-width: 770px;
      color: $darker;
      margin: 0 0 115px;

      @media (max-width: $tablet) {
        margin-bottom: 60px;
      }
    }

    .strategy-arguments-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .strategy-arguments {
        width: 100%;
        max-width: 680px;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        .strategy-argument {
          width: calc((100% - 110px) / 3);
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          font-feature-settings: 'liga' off;
          color: $darker;
          padding: 12px 0 0 5px;
          border-top: 6px solid $darker;
          margin: 0 55px 50px 0;

          &:nth-child(3n+3) {
            margin-right: 0;
          }

          @media (max-width: $tablet) {
            width: calc((100% - 40px) / 3);
            margin-right: 20px;
          }

          @media (max-width: $mobile) {
            font-size: 12px;
            width: calc((100% - 20px) / 2);
            margin-right: 20px;
            border-top: 4px solid $darker;

            &:nth-child(3n+3) {
              margin-right: 20px;
            }

            &:nth-child(2n+2) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

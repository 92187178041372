.wallet-projects002 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 130px 0;
  overflow: hidden;
  background: #163645;

  @media (max-width: $tablet) {
    padding: 60px 0;
    margin-top: 60px;
  }

  .wallet-projects002-container {
    @extend .container;
    display: flex;
    flex-direction: column;

    .projects002-heading {
      color: $white;
      margin: 0 0 120px;
    }

    .projects002-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      .project-item {
        width: calc((100% - 40px) / 3);
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 20px;

        &:nth-child(3n+3) {
          margin-right: 0;
        }

        @media (max-width: $tablet) {
          width: calc((100% - 20px) / 2);

          &:nth-child(3n+3) {
            margin-right: 20px;
          }

          &:nth-child(2n+2) {
            margin-right: 0;
          }
        }

        @media (max-width: $mobile) {
          width: 100%;
          margin-right: 0;

          &:nth-child(3n+3) {
            margin-right: 0;
          }
        }

        .item-logo-container {
          width: 100%;
          display: flex;

          .item-logo {
            width: 100%;
          }
        }

        .item-footer {
          flex: 1;
          min-height: 140px;
          width: 100%;
          display: flex;
          padding: 30px 25px;
          background-color: $colorAccent3;

          @media (max-width: $tablet) {
            padding: 20px 15px;
          }

          .item-description {
            font-family: $fontHaas;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 130%;
            color: $white;
            margin: 0;
          }
        }
      }
    }
  }
}

.target-value {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 220px 0 180px;
  overflow: hidden;
  background-color: $darker;

  @media (max-width: $tablet) {
    padding: 60px 0 50px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 57vw;
    top: -3vw;
    width: 52vw;
    height: 52vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NTAgNzUwIj48cGF0aCBvcGFjaXR5PSIuMyIgdHJhbnNmb3JtPSJzY2FsZSgtMSAxKSByb3RhdGUoLTQ1IDEzMS4xNjUgOTYzLjY3KSIgZmlsbD0idXJsKCNhKSIgZD0iTTAgMGgzODB2MzgwSDB6Ii8+PHBhdGggb3BhY2l0eT0iLjMiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDM3NC43NjcgMCkiIGZpbGw9InVybCgjYikiIGZpbGwtb3BhY2l0eT0iLjUiIGQ9Ik0zNzQuNzY3IDBoNTMwdjUzMGgtNTMweiIvPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgeDE9IjE5MCIgeTE9IjAiIHgyPSIxOTAiIHkyPSIzODAiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjRjJGN0ZGIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjRjJGN0ZGIiBzdG9wLW9wYWNpdHk9Ii40Ii8+PC9saW5lYXJHcmFkaWVudD48bGluZWFyR3JhZGllbnQgaWQ9ImIiIHgxPSI2MzkuNzY3IiB5MT0iMCIgeDI9IjYzOS43NjciIHkyPSI1MzAiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjZmZmIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZmIiBzdG9wLW9wYWNpdHk9IjAiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48L3N2Zz4=");

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 50vw;
    bottom: -3vw;
    width: 52vw;
    height: 35vw;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NTkgNTA0Ij48cGF0aCBvcGFjaXR5PSIuMyIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDAgNTA0KSIgZmlsbD0idXJsKCNhKSIgZD0iTTAgNTA0aDUwNHY1MzdIMHoiLz48cGF0aCBvcGFjaXR5PSIuMyIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDI4NSA1MDQpIiBmaWxsPSJ1cmwoI2IpIiBkPSJNMjg1IDUwNGgzOTh2NDc0SDI4NXoiLz48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgxPSIyNTIiIHkxPSI1MDQiIHgyPSIyNTIiIHkyPSIxMDQxIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agc3RvcC1jb2xvcj0iI0YyRjdGRiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0YyRjdGRiIgc3RvcC1vcGFjaXR5PSIuNCIvPjwvbGluZWFyR3JhZGllbnQ+PGxpbmVhckdyYWRpZW50IGlkPSJiIiB4MT0iNDg0IiB5MT0iNTA0IiB4Mj0iNDg0IiB5Mj0iOTc4IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agc3RvcC1jb2xvcj0iI0YyRjdGRiIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0YyRjdGRiIgc3RvcC1vcGFjaXR5PSIuNCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjwvc3ZnPg==");

    @media (max-width: $mobile) {
      display: none;
    }
  }

  .target-value-container {
    position: relative;
    z-index: 2;
    @extend .container;
    display: flex;
    flex-direction: column;

    .target-hero-heading {
      font-family: $fontArnhem;
      color: $white;
      margin: 0 0 55px;

      @media (max-width: $mobile) {
        margin-bottom: 20px;
      }
    }

    .target-hero-text {
      @extend .regularText;
      width: 100%;
      max-width: 770px;
      color: $white;
      margin: 0 0 115px;

      @media (max-width: $tablet) {
        margin-bottom: 60px;
      }
    }

    .target-arguments-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .target-arguments {
        width: 100%;
        max-width: 680px;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        .target-argument {
          width: calc((100% - 110px) / 3);
          font-family: $fontArnhem;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 150%;
          font-feature-settings: 'liga' off;
          color: $white;
          padding: 12px 0 0 5px;
          border-top: 6px solid $white;
          margin: 0 55px 50px 0;

          &:nth-child(3n+3) {
            margin-right: 0;
          }

          @media (max-width: $tablet) {
            width: calc((100% - 40px) / 3);
            margin-right: 20px;
          }

          @media (max-width: $mobile) {
            font-size: 12px;
            width: calc((100% - 20px) / 2);
            margin-right: 20px;
            border-top: 4px solid $white;

            &:nth-child(3n+3) {
              margin-right: 20px;
            }

            &:nth-child(2n+2) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

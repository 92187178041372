.home-projects001 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 100px 0;
  overflow: hidden;

  @media (max-width: $tablet) {
    padding: 60px 0;
  }

  .home-projects001-container {
    @extend .container;
    display: flex;
    flex-direction: column;
    align-items: center;

    .projects001-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 55px;

      @media (max-width: $mobile) {
        margin-bottom: 40px;
      }

      .projects001-heading {
        color: $darker;
        margin: 0;
      }

      .projects001-button {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 125%;
        color: $darker;
        margin: 0;

        &:after {
          content: '';
          display: flex;
          width: 24px;
          height: 24px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3IDExIj48cGF0aCBkPSJNNi41IDUuODRhLjk3NS45NzUgMCAwIDEtLjI5LjdsLTQgMy45NDdhMS4wMDUgMS4wMDUgMCAwIDEtLjcxLjI5IDEuMDE2IDEuMDE2IDAgMCAxLS43MS0uMjkuOTkuOTkgMCAwIDEtLjI5NC0uNy45OC45OCAwIDAgMSAuMjk0LS43TDQuMSA1Ljg0LjkyIDIuNTg0YS45ODcuOTg3IDAgMCAxLS4zMzEtLjcxOC45NzUuOTc1IDAgMCAxIC4zMDgtLjcyOCAxLjAwMiAxLjAwMiAwIDAgMSAuNzUtLjI3MyAxLjAxIDEuMDEgMCAwIDEgLjcxMy4zNTdMNi4yMiA1LjE3YS45OC45OCAwIDAgMSAuMjguNjcxWiIgZmlsbD0iIzA2MkQzRiIvPjwvc3ZnPg==");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto 10px;
        }
      }
    }

    .projects001-list {
      width: 100%;
      display: flex;

      .swiper-wrapper {
        width: 100%;
        display: flex;
      }

      .project-item {
        width: 350px;
        display: flex;
        flex-direction: column;
        margin-right: 35px;

        @media (max-width: $tablet) {
          width: 250px;
          margin-right: 20px;
        }

        .item-logo-container {
          width: 100%;
          display: flex;

          .item-logo {
            width: 100%;
          }
        }

        .item-footer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 25px;
          background-color: $colorAccent3;

          @media (max-width: $tablet) {
            padding: 20px 15px;
          }

          span {
            width: calc(100% - 40px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: $fontArnhem;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 155%;
            color: $white;

            @media (max-width: $tablet) {
              font-size: 16px;
            }
          }

          svg {
            height: 20px;

            @media (max-width: $tablet) {
              height: 16px;
            }
          }
        }
      }
    }
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  transition: all ease-out .3s;

  @media (max-width: $tablet) {
    padding: 20px 0;
  }

  &:before {
    position: absolute;
    content: '';
    inset: 0;
    background: linear-gradient(112.73deg, #104E65 0.12%, #012030 100%);
    opacity: 0;
    transition: opacity ease-out .2s;
  }

  .header-container {
    @extend .container;
    z-index: 12;
    align-items: center;
    justify-content: space-between;
  }

  &.is-fixed, &.is-always-fixed {
    padding: 20px 0;

    &:before {
      opacity: 1;
    }

    .header-logo svg {
      height: 30px;
    }
  }

  .header-logo {
    display: flex;

    svg {
      height: 70px;
      transition: height ease-out .2s;

      @media (max-width: $tablet) {
        height: 40px;
      }
    }
  }

  .right-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .header-mobile-trigger {
      width: 30px;
      height: 20px;
      display: none;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @media (max-width: $mobile) {
        display: flex;
      }

      svg {
        height: 100%;

        &.mobile-close {
          display: none;
        }
      }

      &.is-active svg {
        &.mobile-burger {
          display: none;
        }

        &.mobile-close {
          display: block;
        }
      }
    }

    .header-link {
      margin-right: 50px;

      @media (max-width: $tablet) {
        margin-right: 25px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .header-link {
    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.header-link {
  position: relative;
  font-family: $fontHaas;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 165%;
  text-transform: uppercase;
  color: #FFFFFF;
  appearance: none;
  outline: none;
  background: transparent;
  border: none;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    bottom: -5px;
    background-color: $white;
    width: 0;
    transition: width ease-out .2s;
  }

  &:hover:before {
    width: 100%;
  }

  &.is-active {
    font-weight: 700;

    &:before {
      bottom: -7px;
      height: 2px;
      width: 100%;
    }
  }
}

.mobile-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 100px 30px 40px;
  //backdrop-filter: blur(8px);
  background: linear-gradient(112.73deg, #104E65 0.12%, #012030 100%);
  display: none;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease-out .2s;

  @media (max-width: $mobile) {
    display: flex;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;

    .menu-links .header-link, .menu-socials {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .menu-links {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .header-link {
      margin-bottom: 40px;
      opacity: 0;
      transform: translateX(-50px);
      transition: all ease-out .2s;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: calc(.1s * #{$i});
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .menu-socials {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: all ease-out .2s;
    transition-delay: .9s;

    .social-link {
      display: flex;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      svg {
        height: 16px;
      }
    }
  }
}
